import React from 'react';
import ContextProvider from '../components/ContextProvider';
import LegalStuff, { LegalStuffType } from '../components/LegalStuff';

const AppTerms = ({ localeCode }: { localeCode?: string }) => (
  <ContextProvider localeCode={localeCode}>
    <LegalStuff type={LegalStuffType.APP_TERMS} />
  </ContextProvider>
);

export default AppTerms;
